@import '../config/variables';

body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.page {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: white;
  left: 50%;
  transform: translateX(-50%)
}

.contentMinHeight {
  min-height: 200px;
}

.pageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}

.filterContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.filterGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  & .group1 {
    padding-right: 10px;
  }
}

.fiterDropdown {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-height: 768px) {
  .page {
    height: 600px;
  }
}

@media screen and (max-height: 1024px) {
  .page {
    height: 800px;
  }
}