@import "../../config/variables";

.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 100%;
  }

  .disabled {
    cursor: default;  
  }

  &.rightSpace {
    margin-right: 5px;
  }
}

.listBox {
  margin: 5px 80px 40px 0;
  .listItemTitle {
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: bolder;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $black;
    &:last-child {
      border-bottom: 1px solid $black;
    }
    .item-block {
      padding: 10px 0;
      display: flex;
      align-items: center;
      .square {
        width: 50px;
        height: 50px;
        background: $grey;
        margin: 0 15px;
      }
      form.check {
        display: flex;
        align-items: center;

        input {
          width: 20px;
          height: 20px;
        }
      }
    }
    .flexed {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      .img {
        width: 27px;
        height: 27px;
        margin-bottom: auto;
      }
      .p{
        margin-top: auto;
        align-content: flex-end;
      }  
    }
    .mx-3{
      padding-left: 30px;
    }
  }
}
