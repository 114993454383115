@import '../config/variables';

.box {
  max-width: 90%;
  min-height: fit-content;;
  height: 100%;
  
  margin: 0px auto;
  background-color: $white;
}

.container {
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  min-height: 80vh;
  max-height: fit-content;

  .menu {
    position: relative;
    display: flex;
    width: 35%;
    max-width: 300px;
  }

  .content {
    background: #fff;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding-top: 30px;
    
    .content {
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  body {
   padding: 0;
    margin: 0;
  }
  body, .fitWidthMedium {
    width: fit-content;
  }

  .box, .container {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  body, .fitWidthMedium {
    width: fit-content;
  }

  .container {
    max-width: 100%;
    padding: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    min-height: 80vh;
    max-height: fit-content;

    .menu {
      display: none;
    }
    .content {
      display: flex;
    }
      
    &.showNavMenu {
      max-width: 100%;
      width: 100%;

      .menu {
        display: flex;
        max-width: 100%;
        width: 100%;
        padding: 5% 10%;
        justify-content: center;
      }
      .content {
        display: none;
      }
    }
  }
}
