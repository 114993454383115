@import '../../config/variables';

.container {
  position: relative;
  display: block;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;

	p {
		font-weight: bold;
	}

  .icon {
    position: relative;
    display: block;
    margin-top: 30px;
    width: 100px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.messageWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  .message {
    font-size: 2rem;
    text-align: center;
    color: $gold;
  }
}
