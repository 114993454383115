.spinner {
  width: 50px;
  height: 50px;
  background: url("../../../assets/spinner.svg") center center no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: calc(50% - 25px);
  -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
  display: block;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}