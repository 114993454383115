@import '../config/variables';

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  background: $white;

  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $black;
    background-color: $grey-lighter;
    border-radius: 6px;
    text-align: left;

    border: 3px solid $grey-lighter;
    margin: 10px;
    padding: 30px 25px;

    .content {
      position: relative;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      background-color: $alto;
      border: 3px solid $alto;
      cursor: pointer;
      box-shadow: 0 0.5em 0.5em -0.4em $black;
      transform: translateY(-0.25em);
    }
  }
}
