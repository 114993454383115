@import '../config/variables';

.container {
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .message {
      font-size: 7rem;
      font-weight: bold;
      color: $grey-lighter;
      text-align: center;
      user-select: none;
    }
  }
}

.backBottom {
  width: min-content;
	position: relative;
  height: 100px;
  display: flex;
  justify-content: center;
  width: 100%;

  .wrapper {
    position: absolute;
		width: fit-content;
    padding: 30px 0;

    button {
      background: $grey-lighter;
      border-radius: 6px;
      padding: 15px 40px;
      cursor: pointer;

      &:hover {
        background-color: $grey-lighter;
        border: 3px solid $grey-lighter;
        box-shadow: 0 0.5em 0.5em -0.4em $black;
        transform: translateY(-0.25em);
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .container {
    .wrapper {
      .message {
        font-size: 5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .wrapper {
      .message {
        font-size: 4.5rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .wrapper {
      .message {
        font-size: 4rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .wrapper {
      .message {
        font-size: 3.5rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .wrapper {
      .message {
        font-size: 3em;
      }
    }
  }
}
