@import '../config/variables';

@media print {
  .declaration {
    .h4 {
      break-before: always;
      break-after: avoid;
    }

    table {
      border: 0px;
      break-inside: avoid;
    }

    th {
      break-inside: avoid;
    }
  }
}

.grow {
  table-layout: fixed;
  width: 100%
}

table {
  width: 100%;
  border-collapse: collapse;
}

td, th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  text-align: left;
}

.border {
  border: 1px solid $black;
}
