@import '../config/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.buttonWrapper {
  width: 90%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  padding-bottom: 10px;
  max-height: 70px;
  height: 70px;
}

.button {
  position: absolute;
  right: 0;
  display: inline-block;
  margin: 20px 0;
  padding: 5px 50px;
  font-size: 1rem;
  background-color: $white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  z-index: 100000;
  
  &:hover {
    background-color: $alto;
    border: 3px solid $alto;
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em $black;
    transform: translateY(-0.25em);
  }
}
