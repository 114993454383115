@media print {
  .disclaimer {
    page-break-before: avoid;
    page-break-inside: avoid;
    page-break-after: always;

    h4 {
      font-size: 14px;
    }
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  text-align: left;
  bottom: 0;
  margin: 0 5px 30px 0;
  h4 {
    margin-bottom: 10px;
  }
  p {
    font-size: 0.7rem;
  }
}
