@import "../config/variables";

.settings {
  cursor: pointer;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $grey-lighter;
  }
}

.dropdown {
  position: relative;
  display: block;
  z-index: 1000;

  .dropdownWrapper {
    position: absolute;
    right: 0;
  }

  .container {
    position: relative;
    display: block;
    width: 120px;
    height: 40px;
    background-color: $white;
    border-radius: 3px;
    border: 1px solid $grey;
    text-align: center;

    .item {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding: 10px;
      color: $black;
      
      background-color: $white;
      text-align: left;
      cursor: pointer;
      user-select: none;
      font-size: 14px;

      &:hover {
        color: $gold;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 500px) {
  .settings img {
    width: 32px;
    height: 32px;
  } 
}
