@import '../config/variables';

.item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid black;
    cursor: pointer;
    padding: 20px 10px;
    background-color: white;
    z-index: 1000;

    &:hover {
        font-weight: bold;
        color: $gold-light;
    }
}

.itemWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.highlight {
    color: $gold;
}