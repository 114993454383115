@import "../config/variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: $white;
  padding: 20px;

  .container {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .circle {
    width: 50px;
    height: 50px;
    background-color: $grey-lighter;
    border-radius: 50%;
  }

  .logo img {
    width: 50px;
    height: 50px;
  }
  form {
    display: flex;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    align-items: center;

    input {
      padding-left: 15px;
      margin: 0 auto;
      width: 98%;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      color: $black;
      background-color: $whitish;
      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .navbar {
    padding: 10px;

    .circle {
      width: 32px;
      height: 32px;
    }
    .logo img {
      width: 32px;
      height: 32px;
    }
    form {
      input {
        height: 28px;
        font-size: 10px;
      }
      .searchIcon {
        width: 10px;
        height: 10px;
      }
    }
  }
}
