@import '../config/variables';

.container {
  display: block;
  position: relative;
  width: 100%;
}

.contentContainer {
  display: block;
  height: 400px;
  overflow-y: hidden;
  overflow-y: auto;
}

.navigation {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

  .dots {
    display: inline-block;
    color: $gold;
  }
}

.pageIndex {
  display: inline-block;
  padding: 4px;
  color: $gold;
  cursor: pointer;
  user-select: none;
  
  &.active {
    color: black;
  }

  &:hover {
    text-decoration: underline;
    scale: (1.2);
  }
}

@media screen and (min-height: 1024px) {
  .contentContainer {
    height: 600px;
  }
}

@media screen and (max-height: 768px) {
  .contentContainer {
    height: 500px;
  }
}

@media screen and (min-height: 768px) {
  .contentContainer {
    height: 460px;
  }
}

@media screen and (max-height: 500px) {
  .contentContainer {
    height: 200px;
  }
}
