@import "../config/variables";

.container {
  position: relative;
  display: block;
  border: 1px solid $black;
  margin-top: 10px;
}

.containerWrapper {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: $white;

  .leftContainer {
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
    }
      
    .thumbnail {
      width: 50px;
      height: 50px;
      background: $grey;
      margin: 0 15px;
      background-size: 50px;
    }

    .info {
      display: flex;
      flex-direction: column;
    }

    .details {
      display: flex;
      margin-left: 80px
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .expend {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      & .expended {
        transform: rotate(180deg);
      }
    }
  }
}

.check {
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 100%;
  }

  .disabled {
    cursor: default;  
  }
}

.price {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
