@import '../config/variables';

.container {
  display: flex;
  border: 1px solid $grey-lighter;
  padding: 20px 10px;

  &:hover {
      border: 1px solid $grey-darker;
  }
}

.pointer {
  cursor: pointer;
}

.externalLink {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
  margin-top: -1px;

  &.search {
    background-image: url(../../assets/external_link.svg);
  }

  &.receipt {
    background-image: url(../../assets/print.svg);
  }

  &.edit {
    background-image: url(../../assets/edit.svg);
  }
}

.itemWrapper {
  display: inline-block;
  width: 100%;
  height: 20px;
}

.item {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
  padding-right: 15px;
}

.alignRight {
  text-align: right;
}

.orderIdCell {
  display: inline-block;
  width: 30%;
}

.customerCell {
  display: inline-block;
  width: 30%;
}

.dateCell {
  display: inline-block;
  width: 30%;
}

.priceCell {
  display: inline-block;
  width: 10%;
}

@media screen and (max-width: 768px) {
  body {
    padding: 0;
  }

  .orderIdCell {
    display: inline-block;
    width: 40%;
  }

  .customerCell {
    display: inline-block;
    width: 40%;
  }

  .dateCell {
    display: none;
  }

  .priceCell {
    display: inline-block;
    width: 20%;
  }
}

@media screen and (max-width: 500px) {
  body {
    padding: 0;
  }

  .orderIdCell {
    display: inline-block;
    width: 70%;
  }

  .customerCell {
    display: none;
  }

  .dateCell {
    display: none;
  }

  .priceCell {
    display: inline-block;
    width: 30%;
  }
}
