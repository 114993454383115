@import '../config/variables';

@media print {
  .container {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-after: auto;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $black;
  padding: 10px;
}

.productInfo {
  margin: 0 5px;
  text-align: left;

  .header {
    padding-left: 5px;
    margin-bottom: 20px;
  }

  div {
    padding: 1px 0;
  }
}

.alignRight {
  text-align: right;
}

.thumbnail {
  width: 70px;
  height: 70px;
  background-color: $grey;
  background-size: 70px;
}

.infoTable {
  border: 1px solid $black;
  width: calc(50% - 5px);
  padding: 20px;
  margin-top: 20px;

  &.leftTable {
    padding-right: 5px;
  }

  &.rightTable {
    padding-right: 5px;
  }
}

.infoLeftTable {  
  border: 1px solid $black;
}

.infoRightTable {
  padding-left: 5px;
  width: calc(50% - 5px);
}

.propertyRow {
  padding: 5px 0;
}

.priceContainer {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: space-between;
  flex-direction: column;
}