@import '../config/variables';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input, a, button {
  font-family: inherit;
  font-weight: 600;
}

body {
  font-family: $font-hema;
  background-color: $grey-lighter;
  min-width: 100vw;
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
}

@font-face {
  font-family: 'FuturaHEMAProOT';
  src: url('../fonts/FuturaHEMAProOT-Book.woff2') format('woff2'),
    url('../fonts/FuturaHEMAProOT-Book.woff') format('woff'),
    url('../fonts/FuturaHEMAProOT-Book.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaHEMAProOT';
  src: url('../fonts/FuturaHEMAProOT-DemiBold.woff2') format('woff2'),
    url('../fonts/FuturaHEMAProOT-DemiBold.woff') format('woff'),
    url('../fonts/FuturaHEMAProOT-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaHEMAProOT';
  src: url('../fonts/FuturaHEMAProOT-Bold.woff2') format('woff2'),
    url('../fonts/FuturaHEMAProOT-Bold.woff') format('woff'),
    url('../fonts/FuturaHEMAProOT-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@media print {
  .printW100 {
    width: 100% !important;
  }

  .pageBreakBefore {
    page-break-before: always;
  }
  .pageBreakAfter {
    page-break-after: always;
  }

  .noPageBreakBefore {
    page-break-before: avoid;
  }

  .noPageBreakInside {
    page-break-inside: avoid !important;
  }

  .noPageBreak {
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;
  }

  .container {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-after: auto;
  }

  .hideOnPrint {
    display: none;
  }

  .showOnPrint {
    display: block;
  }
}



.hide {
  display: none !important;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  justify-content: left;
}

.alignRight {
  justify-content: right;
}

.textLeft {
  text-align: left;
}

.alignItemBottom {
  align-self: end;
}

.h3 {
  font-size: 1.3rem;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w100 {
  min-width: 100%;
  width: 100%;
}

.h100 {
  min-height: 100%;
  height: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyRight {
  justify-content: right;
}

.mb10 {
  margin-bottom: 10px;
}

.mb30 {
  margin-bottom: 30px;
}

.mr10 {
  margin-right: 10px;
}

.block {
  display: block;
}

.collapse {
  overflow: hidden;
  height: auto;
  max-height: 0;

  &.open {
    max-height: 10000px;
    transition: max-height 1.5s linear;
  }
}
.box {
  max-width: 90%;
  margin: 0px auto;
  background-color: $white;
}

.content {
  img {
    width: 100%;
    height: 100%;
  }
}
.back {
  text-decoration: none;
  color: $black;
  user-select: none;

  &:hover {
    color: $gold;
  }
}

.redText {
  color: red;
}

@media (max-width: 500px) {
  .showcase {
    display: flex;
    flex-direction: column;
    .content {
      display: none;
    }

    .btn {
      height: 250px;

      p {
        font-size: 22px;
        margin-left: 50px;
      }
    }
  }
}


@media screen and (max-width: 1280px) {
  h1,
  .totalPrice div {
    font-size: 2rem;
  }

  div, span, p, th, td {
    font-size: 1rem;
  }

  label,
  input {
    font-size: 0.8rem;

    &::placeholder {
      font-size: 0.8rem;
    }
  }

  .smallText,
  .smallText p {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1280px) {
  h1,
  .totalPrice div {
    font-size: 3rem;
  }

  div, span, p, th, td {
    font-size: 1rem;
  }

  label, input {
    font-size: 1rem;

    &::placeholder {
      font-size: 1rem;
    }
  }

  .smallText, .smallText p {
    font-size: 0.9rem;
  }
}


@media screen and (max-width: 1024px) {
  h1, .totalPrice div {
    font-size: 2rem;
  }

  div, span, p, th, td {
    font-size: 0.9rem;
  }

  label, input {
    font-size: 0.8rem;

    &::placeholder {
      font-size: 0.8rem;
    }
  }

  .smallText, .smallText p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  body, .fitWidthMedium {
    width: fit-content;
  }

  h1 {
    font-size: 1.5rem;
  }
}


@media screen and (max-width: 500px) {
  body, .fitWidthMedium {
    width: fit-content;
  }

  * {
    div, span, p, th, td {
      font-size: 1rem;
    }

    h1 {
      font-size: 1.4rem;
    }

    .smallText, .smallText p {
      font-size: 0.7rem;
    }
  }

  label, input {
    font-size: 0.9rem;
  }

  .w50Resize {
    width: 50%;
  }
}
