@import '../config/variables';

.button {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    border: 2px solid $grey-light;
    padding: 10px;
    text-align: center;
    background-color: white; 
    justify-content: center;
    user-select: none;
}

.container::after {
    content: "\25BC";
    position: absolute;
    right: 10px;
    color: $grey-dark;
}

.item {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white; 
    cursor: pointer;
    border: 1px solid $grey-darker;
    padding: 10px;
    text-align: center;
    z-index: 1000;
    user-select: none;

    &:hover {
        font-weight: bold;
        color: $gold;
    }
}

.itemWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.itemsContainer {
    width: 100%;
    height: 100%; 
    background-color: white; 
}