@import '../config/variables';

.dots {
  display: block;
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  border-bottom: 1px solid $borderColorTwo;
  border-top: 1px solid $borderColorTwo;
  div {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: $borderColorTwo;
    margin: 4px 5px;
    border-radius: 50%;
  }
}