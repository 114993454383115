.container {
    display: flex;
    width: 100%;
    height: 30px;
    justify-content: space-between;
}
.input {
    width: 100%;
    height: 30px
}
.button {
    padding: 5px 10px;
    margin-left: 5px;
}
