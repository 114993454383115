@import '../config/variables';

@media print {
  @page {
    margin-left: 0.3cm;
    margin-right: 0.3cm;
    background-color: $white;

    p {
      font-size: 21em;
    }
  }

  body {
    overflow: visible;
    padding: 0;
    margin: 0;
    background-color: $white;
    width: 100%;
    position: relative;
    display: block;
    left: 50%;
    transform: translateX(-50%);

    -webkit-print-color-adjust:exact;

    p {
      font-size: 21em;
    }

    div, span {
      font-size: 12px;
    }

    h1, h2, h3, h4 {
      font-size: 14px;
    }

    th, td {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      font-size: 12px;
    }

    td {
      font-size: 10pt;
    }

    .customerInfoTable {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
              justify-content: space-between;

      th, td, h1, h2, h3, h4 {
        font-size: 12px;
        overflow: visible;
      }
    }
    
  }

  .page {
    padding: 0;
  }
}

.customerInfoTable {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex {
  display: flex;
}

.flexStretch {
  display: flex;
  flex: 1;
}

.signatureContainer {
  display: flex;
  justify-content: right;
  padding-top: 40px;

}

.signatureWrapper {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin: 0px 0px 20px 0px;
}

.signatureLine {
  width: 300px;
  margin-top: 80px;
  border-bottom: 1px solid $black;
}

h3 {
  margin: 30px 0px;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 30px;
}

.signature {
  width: 300px;
  margin: 20px 20px 50px auto;
  border-bottom: 1px solid $black;
}

.container {
  overflow: auto;
  width: 90%;
  margin: 0 auto;
  background-color: $white;
  padding-bottom: 0;
}

.priceContainer {
  width: min-content;
  padding-top: 10px;
}

@media screen and (max-width: 800px) {
  .container {
    width: fit-content;
  }
}