@import "../config/variables";

.form {
  position: relative;
  display: block;
  border-top: 1px solid $borderColorTwo;
  border-bottom: 1px solid $borderColorTwo;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;

  .rowRadioButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .input {
      display: inline-block;
      margin: 10px 3px 10px 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    label {
      margin-right: 25px;
      cursor: pointer;
    }
  }

  .control {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  .textControl {
    position: relative;
    display: inline-block;
    margin-right: 5px;
  }

  .inputLabel {
    margin-right: 10px;
  }

  .textInput {
    display: block;
    margin: 5px 0;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid $grey-light;
    border-radius: 3px;
    &::placeholder {
      color: $grey-light;
    }
    &:focus {
      outline: none;
    }
  }

  .infoText {
    display: inline-block;
    color: $grey-light;
  }

  .errorText {
    color: red;
  }
  
  .errorInput {
    border: 1px solid $red;
  }
}
