@import "../config/variables";

.container {
  position: relative;
  display: block;
  width: 100%;
  min-width: 360px;
  padding: 0px 10px;
  background-color: $white;
}

.priceContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;

  .price {
    font-size: 1em;
    font-weight: bolder;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  
  .primaryButton,
  .secondaryButton{
    flex:1;
    display: inline-block;
    padding: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    &:hover {
      transform: scale(0.99);
      opacity: 0.9;
    }
  }
  .primaryButton {
    background: $gold;
    color: $white;
    margin: 10px 5px 10px 0px;
  }
  .secondaryButton {
    background-color: $borderColor;
    color: $black;
    margin: 10px 0px 10px 5px;
  }
}


@media screen and (max-width: 1024px) {
  .form {
    .errorText {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 1280px) {
  .orderOverviewResize {
    .scaledMiddleName {
      width: 33%;
    }
  }
}

@media screen and (max-width: 800px) {
    .orderOverviewResize {
      .scaledInputs {
        width: 50%;
    }

    .scaledMiddleName {
      width: 35%;
    }
  }
}

