@import '../config/variables';

.navbar {
  background-color: $white;
  padding: 10px;
  .container {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .circle {
    width: 50px;
    height: 50px;
    background-color: $grey-lighter;
    border-radius: 50%;
  }

  .barcodes {
    position: relative;
    display: flex;
  }

  .tooltipContainer {
    .tooltip {
      display: none;
      position: absolute;
      background: aliceblue;
      border: 1px solid black;
      border-radius: 6px;
      padding: 6px;
      z-index: 10000;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  img {
    width: 50px;
    height: 50px;
  }
  form {
    display: flex;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    align-items: center;

    input {
      padding-left: 15px;
      margin: 0 auto;
      width: 98%;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      color: $black;
      background-color: $whitish;
      &:focus {
        outline: none;
      }
    }
    .searchIcon {
      width: 13px;
      height: 13px;
      margin-left: -50px;
    }
  }
}
