@import '../../config/variables';

.emptyState {
    position: relative;
    display: block;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 20px;
}

.emptyState .message {
    font-size: 3rem;
    text-align: center;
    color: $gold;
}

.container {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.emptyState .icon {
    position: relative;
    display: block;
    margin-top: 30px;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
