@import '../config/variables';

.searchContainer {
  width: 80%;
  margin-left: 10%;

  .searchWrapper {
    width: 80%;
    left: 50%;
    display: block;
    position: relative;
    transform: translateX(-50%);
  }

  .input {
    background: $white;
    border: 1px solid $alto;
    border-radius: 2px;
    padding: 10px 20px 10px 10px;
    width: 100%;
    
    &:focus {
      outline: 1px solid $grey;
    }
  }

  .searchIcon {
    width: 13px;
    height: 13px;
    margin-left: -26px;
  }
}

.searchContent {
  width: 100%;
  height: 100%;

  .row {
    margin-bottom: 15px;

    .status {
      display: inline-block;
      border-radius: 6px;
      padding: 2px 10px;
      background-color: $greyish;
    }
  }

  .trace {
    border-top: 1px solid $borderColorTwo;
    margin-top: 35px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.print {
  position: relative;
  margin: 50px 0 20px 0;

  .link {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $gold;
      transform: scale(0.99);

      .icon svg path {
        fill: $gold;
      }
    }

    .icon {
      position: absolute;
      margin-left: 10px;
      top: -1px;
    }
  }
}

.bottomSection {
  margin: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  border-top: 1px solid $borderColor;
  border-radius: 0 0 2px 2px;

  .positiveButton {
    display: flex;
    width: 230px;
    padding: 10px 10px;
    background: $grey-light;
    color: $white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    justify-content: center;

    &.active {
      background: $gold;
    
      &:hover {
        transform: scale(0.98);
        opacity: 0.9;
      }
    }
  }

  .negativeButton {
    display: inline-block;
    padding: 10px 30px;
    margin-left: 4px;
    width: 230px;
    border: none;
    background-color: $borderColor;
    color: $black;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: $alto;
      transform: scale(0.98);
      opacity: 0.9;
    }
  }
}
