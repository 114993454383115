.content {
  margin: 0px 40px 20px 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .text:last-child {
    margin-top: 20px;
  }
  .total {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }
}

.priceContainer {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.priceItem {
  display: flex;
  justify-content: space-between;
}
