@import '../config/variables';

.overviewDetail {
  display: flex;
  flex-direction: column;
  .top {
    margin: 10px 0;
    p {
      line-height: 30px;
    }
  }
  .bot {
    margin-bottom: 10px;
    p {
      line-height: 30px;
    }
  }

  .price {
    
  }
  
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .price {
    margin: 0 20px 10px auto;
  }
}
